import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
import { Polish } from "flatpickr/dist/l10n/pl.js"

// Connects to data-controller="flatpickr-month-picker"
export default class extends Controller {
  initialize() {
    flatpickr(this.element, {
      locale: Polish,
      plugins: [
        new monthSelectPlugin({
          altInput: true,
          dateFormat: "m.Y",
          altFormat: "F, Y"
        })
      ]
    });
  }
}
