import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
import { Polish } from "flatpickr/dist/l10n/pl.js"

// Connects to data-controller="flatpickr-month-timetable"
export default class extends Controller {
  static targets = ["form", "input"];

  initialize() {
    flatpickr(this.element, {
      locale: Polish,
      plugins: [
        new monthSelectPlugin({
          dateFormat: "Y-m-d",
          altFormat: "Y-m-d"
        })
      ],
      onValueUpdate: (dObj, dStr, fp, dayElem) => {
        this.inputTarget.value = dStr;
        this.formTarget.submit()
      }
    });
  }
}
