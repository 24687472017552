import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { Polish } from "flatpickr/dist/l10n/pl.js"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static values = {
    time: Boolean,
    defaultDate: String
  }

  initialize() {
    if(this.timeValue) {
      flatpickr(this.element, {
        locale: Polish,
        defaultDate: this.defaultDateValue,
        enableTime: true,
        dateFormat: "d.m.Y H:i",
      });
    }else{
      flatpickr(this.element, {
        locale: Polish,
        defaultDate: this.defaultDateValue,
        dateFormat: "d.m.Y",
      });
    }
    
  }
}
