import { Controller } from "@hotwired/stimulus"
import { autocomplete } from '@algolia/autocomplete-js';

// Connects to data-controller="autocomplete"
export default class extends Controller {
  static targets = [ "input", "search" ]
  static values = {
    url: String,
    attr: String,
    reqAttr: String
  }

  connect() {
    let oldAutocomplete = this.searchTarget.querySelector(".aa-Autocomplete")
    if(oldAutocomplete != null){
      oldAutocomplete.remove();
    }

    autocomplete({
      container: this.searchTarget,
      openOnFocus: true,
      detachedMediaQuery: 'none',
      initialState: {
        query: this.inputTarget.value,
      },
      onReset: () => {
        this.inputTarget.value = "";
      },
      getSources: ({ query }) => {
        return fetch(`${this.urlValue}?q[${this.reqAttrValue}]=${query}`)
          .then((response) => response.json())
          .then((items) => {
            return [
              {
                sourceId: 'items',
                getItemInputValue: ({ item }) => {
                  return item[this.attrValue];
                },
                getItems: ({ query }) => {
                  return items;
                },
                onSelect: ({ item}) => {
                  this.inputTarget.value = item[this.attrValue];
                  query = item[this.attrValue];
                },
                templates: {
                  item: ({ item }) => {
                    return item[this.attrValue];
                  },
                },
              }
            ]
          })
      }
    })
  }
}