import { Controller } from "@hotwired/stimulus"
import { autocomplete } from '@algolia/autocomplete-js';

// Connects to data-controller="account-attach"
export default class extends Controller {
  static targets = [ "search" ]
  static values = {
    url: String,
    attachUrl: String,
    attr: String,
    addLabelButton: String,
    placeholder: String
  }

  connect() {
    let addLabel = this.addLabelButtonValue;
    if (!addLabel) { addLabel = 'Add'; }

    let oldAutocomplete = this.searchTarget.querySelector(".aa-Autocomplete")
    if(oldAutocomplete != null){
      oldAutocomplete.remove();
    }

    autocomplete({
      container: this.searchTarget,
      openOnFocus: true,
      placeholder: this.placeholderValue,
      detachedMediaQuery: '',
      onReset: ({query}) => {
        this.inputTarget.value = "";
      },
      getSources: ({ query }) => {
        return fetch(`${this.urlValue}&q[email_or_fullname_cont]=${query}`)
          .then((response) => response.json())
          .then((accounts) => {
            return [
              {
                sourceId: 'accounts',
                getItems: ({ query }) => {
                  return accounts;
                },
                templates: {
                  item: ({ item, html }) => {
                    return html`<div class="px-2 my-1 d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center gap-2">
                        <i class="bi bi-person fs-3"></i>
                        <div>
                          <div class="mb-1">${item.firstname} ${item.lastname}</div> 
                          <small class="text-muted">${item.email}</small> <small class="text-warning">${item.role_i18n}</small>
                        </div>
                      </div>
                      <form method="post" action="${this.attachUrlValue}">
                        <input type="hidden" name="${this.attrValue}" value="${item.id}" />
                        <input type="submit" value="${addLabel}" class="btn btn-success" />
                      </form>
                    </div>` ;
                  },
                },
              }
            ]
          })
      }
    })
  }
}
