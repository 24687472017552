import { Controller } from "@hotwired/stimulus"
import { autocomplete } from '@algolia/autocomplete-js';

// Connects to data-controller="timetable-companies"
export default class extends Controller {
  static targets = ["search" ]
  static values = {
    searchUrl: String,
    companyUrl: String,
    placeholder: String
  }

  connect() {
    let oldAutocomplete = this.searchTarget.querySelector(".aa-Autocomplete")
    if(oldAutocomplete != null){
      oldAutocomplete.remove();
    }

    autocomplete({
      container: this.searchTarget,
      openOnFocus: true,
      detachedMediaQuery: 'none',
      placeholder: this.placeholderValue,
      getSources: ({ query }) => {
        return fetch(`${this.searchUrlValue}?q[name_cont]=${query}`)
          .then((response) => response.json())
          .then((items) => {
            return [
              {
                sourceId: 'companies',
                getItems: ({ query }) => {
                  return items;
                },
                onSelect: ({ item}) => {
                  Turbo.visit(`${this.companyUrlValue}?company_id=${item.id}`)
                },
                templates: {
                  item: ({ item }) => {
                    return item.name;
                  },
                },
              }
            ]
          })
      }
    })
  }
}
