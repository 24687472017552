import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="files-uploader"
export default class extends Controller {
  static targets = [ "filesInput", "filesList" ]

  onFilesAttach() {
    const files = this.filesInputTarget.files;
    let html = ""

    for(let i = 0; i < files.length; i ++){
      const file = files[i];
      html += `<li class="list-group-item d-flex align-items-center">
        <i class="bi bi-file-earmark-text fs-5"></i> 
        <div class="ms-2">
          <div>${file.name}</div>
          <div class="text-muted"><small>${this.bytesToSize(file.size)}</small></div>
        <div>
      </li>`
    }

    this.filesListTarget.innerHTML = html;
  }

  bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  initialize() {

    addEventListener("direct-upload:initialize", event => {
      const { target, detail } = event
      const { id, file } = detail
      const element = document.getElementById(`direct-upload-${id}`)
      this.filesListTarget.innerHTML = "";

      if(!element){
        target.insertAdjacentHTML("afterend", `
          <div class="d-flex align-items-center mt-2">
            <span class="me-2 fs-6 text-truncate col-6">${file.name}</span>
            <div id="direct-upload-${id}" class="progress w-100" style="height: 20px;">
              <div id="direct-upload-progress-${id}" class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
            </div>
          </div>
        `)
      }

      
    })

    // addEventListener("direct-upload:start", event => {
    //   const { id } = event.detail
    //   const element = document.getElementById(`direct-upload-${id}`)
    //   element.classList.remove("direct-upload--pending")
    // })

    addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail
      const progressElement = document.getElementById(`direct-upload-progress-${id}`)
      progressElement.textContent = `${progress}%`
      progressElement.style.width = `${progress}%`
    })

    addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      const element = document.getElementById(`direct-upload-progress-${id}`)
      element.classList.add("bg-danger")
      // element.setAttribute("title", error)
    })

    addEventListener("direct-upload:end", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-progress-${id}`)
      element.classList.add("bg-success")
    })
  }
}
