import { Controller } from "@hotwired/stimulus"
import { autocomplete } from '@algolia/autocomplete-js';

// Connects to data-controller="autocomplete-select"
export default class extends Controller {
  static targets = [ "input", "search" ]
  static values = {
    url: String,
    attr: String,
    inputAttr: String,
    reqAttr: String
  }

  connect() {
    let oldAutocomplete = this.searchTarget.querySelector(".aa-Autocomplete")
    if(oldAutocomplete != null){
      oldAutocomplete.remove();
    }
    
    autocomplete({
      container: this.searchTarget,
      openOnFocus: true,
      detachedMediaQuery: 'none',
      initialState: {
        query: this.getInitValue(),
      },
      onReset: () => {
        this.inputTarget.value = "";
      },
      getSources: ({ query }) => {
        return fetch(`${this.urlValue}?q[${this.reqAttrValue}]=${query}`)
          .then((response) => response.json())
          .then((items) => {
            return [
              {
                sourceId: 'items',
                getItems: ({ query }) => {
                  return items;
                },
                onSelect: ({ item}) => {
                  console.log(this.inputTarget)
                  this.inputTarget.value = item[this.inputAttrValue];
                  this.inputTarget.style.display="block"
                  this.searchTarget.style.display="none"
                },
                templates: {
                  item: ({ item }) => {
                    return item[this.attrValue];
                  },
                },
              }
            ]
          })
      }
    })

    this.setupInput();
  }

  setupInput() {
    let searchTargetInput = this.searchTarget.querySelector("input")

    this.inputTarget.addEventListener("mousedown", (event) => {
      event.preventDefault();
      this.inputTarget.style.display="none"
      this.searchTarget.style.display="block"
      searchTargetInput.focus()
    })

    searchTargetInput.addEventListener("focusout", () => {
      this.inputTarget.style.display="block"
      this.searchTarget.style.display="none"
    })

    this.searchTarget.style.display="none";
  }

  getInitValue() {
    if(this.inputTarget.options[this.inputTarget.value]){
      return this.inputTarget.options[this.inputTarget.value].text
    }
    return ""
  }
}
