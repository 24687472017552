import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "kindSelect", "kindNameInput" ]

  connect() {
    // console.log('Custom kind controller connect');
  }

  initialize(){
    if(this.kindSelectTarget.value !== "custom"){
      this.kindNameInputTarget.disabled = true;
    }
  }

  onChangeKind(event) {
    if(event.target.value === "custom"){
      this.kindNameInputTarget.disabled = false;
    }else{
      this.kindNameInputTarget.disabled = true;
      this.kindNameInputTarget.value = "";
    }
  }

}