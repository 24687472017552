import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    title: String,
    message: String,
    okButton: String,
    cancelButton: String,
    method: String,
    url: String,
  }

  connect() {
    // console.log('Confirm controller connect');
  }

  click(event) {
    event.preventDefault();

    let title = this.titleValue;
    let msg = this.messageValue;
    let ok = this.okButtonValue;
    if (!ok) { ok = 'OK'; }
    let cancel = this.cancelButtonValue;
    if (!cancel) { cancel = 'Cancel'; }
    let method = this.methodValue;
    if (!method) { method = 'delete'; }
    let url = this.urlValue;

    let html = `
      <div class="modal py-5" id="confirmDialog" tabindex="-1" role="dialog" aria-labelledby="ariaConfirmModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body p-4 text-center">
              <h5 class="mb-1 fw-normal">${title}</h5>
              <p class="mb-0">${msg}</p>
            </div>
            <div class="modal-footer flex-nowrap p-0">
              <button type="button" class="btn btn-lg btn-link text-muted fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">
                ${cancel}
              </button>
              <a href="${url}" class="btn btn-lg btn-link text-danger fs-6 text-decoration-none col-6 m-0 rounded-0 border-start"
                data-turbo-method="${method}">
                ${ok}
              </a>
            </div>
          </div>
        </div>
      </div >
      `;


    var modalWrapper = document.createElement('div');
    modalWrapper.id = 'modal-wrapper';
    modalWrapper.innerHTML = html;
    document.body.append(modalWrapper);

    let modal = document.querySelector('#confirmDialog');
    var confirmModal = new Modal(modal);
    confirmModal.show();

    modal.addEventListener('hidden.bs.modal', function (event) {
      modalWrapper.remove();
    })

  }

}